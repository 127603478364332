import React, { Component } from 'react'

export default class Post extends Component {
    constructor(props){
        super(props);


        this.state = {
            posts: [],
            loading: true
        }

    }

    componentDidMount(){
        this.loadPostsFromStorage();
        fetch('https://blog.camelot-group.com/wp-json/wp/v2/posts')
            .then(response => response.json())
            .then(posts => {
                this.setState({posts, loading: false})
                this.savePostsToStorage(posts);
            })
            .catch(error => console.error(error))
    }

    savePostsToStorage(posts){
        localStorage.setItem("react-pwa-posts", JSON.stringify(posts));
    }
    
    loadPostsFromStorage(){
        const posts = JSON.parse( localStorage.getItem("react-pwa-posts") );
        this.setState({
            posts,
            loading: false
        })
    }

    render() {
        const {loading, posts} = this.state;
        return (
            <div>
                {loading && (<div>Loading…</div>)}
                {
                    ! loading && posts.length > 0 && posts.map((post, index) => {
                        return (
                            <div key={index}>
                                <h2>
                                    {post.title.rendered}
                                </h2>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}
